import styled from 'styled-components'

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  div.titulo {
    display: flex;
    margin-bottom: 15px;
    align-items: center;

    strong {
      text-align: center;
      font-size: 18px;
      color: #229890;

      display: flex;
      justify-content: center;
      width: 90%;
    }

    svg {
      font-size: 18px;
      color: #229890;
      cursor: pointer;
      transform: scale(1);
      transition: all 0.2s ease-in-out;
      width: 10%;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  > button {
    margin: 0 auto;
  }


  @media screen and (max-width: 766px) {
    padding: 20px 15px;

    > small {
      width: 90%;
    }
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 20px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`
