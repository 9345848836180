/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'
import Switch from 'react-switch'
import * as Yup from 'yup'

import { FiSmartphone, FiMail } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'
import api from '../../services/api'

import InputHidden from '../../components/InputHidden'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'

import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'
import { Container, Content, RadioButton, BtnContato, Line } from './styles'
import clientConfig from '../../services/clientConfig'
import ModalBox from '../../components/Modal'

const Register: React.FC = () => {
  const { codCliente, tipo, plano } = clientConfig()
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  useEffect(() => {
    setConfigData({
      ...configData,
      codCliente,
      plano,
      tipo,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)
  const [tipoContato, setTipoContato] = useState('S')
  const [clicado, setClicado] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  // const handleAdmissao = useCallback(
  //   async (cpf) => {
  //     const parametros = Base64.encode(
  //       `{
  //         "cliente": "${configData.codCliente}",
  //         "cpf": "${cpf.replaceAll('.', '').replace('-', '')}"
  //       }`,
  //     )

  //     const parametrosFinal = Base64.encode(parametros)

  //     await api
  //       .get(`wsAdmissao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
  //       .then(res => {
  //         setDtAdm(res.data.dataAdmissao)
  //       })
  //       .catch(() => {
  //         alert('Ops, algo deu errado. Tente novamente mais tarde.')
  //       })
  //   }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          cpf: Yup.string()
            .required('Digite o CPF do titular')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          tipContato: Yup.string().required(),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })
        setClicado(true)

        await setUserData({
          ...userData,
          cpf: data.cpf,
          email: data.email,
          phone: data.phone,
        })

        const parametros = Base64.encode(
          `{
            "versao":"${configData.tipo}",
            "plano": "${configData.plano}",
            "cliente":"${configData.codCliente}",
            "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
            "email":"${data.email === undefined ? '' : data.email}",
            "telefone":"${
              data.phone === undefined
                ? ''
                : `55${data.phone
                    .replace('(', '')
                    .replace(') ', '')
                    .replace('-', '')}`
            }",
            "envio":"${tipoContato}"
          }`,
        )

        const parametrosFinal = Base64.encode(parametros)
        // history.push('/register/confirm-sms')
        await api
          .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setConfigData({ ...configData, token: res.data.token, tipoContato })

            history.push('/register/confirm-sms')
          })
          .catch(res => {
            if (res.message === 'Network Error') {
              setErroProps({
                title: res.message,
                description: 'Falha na conexão como servidor',
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 500') {
              setErroProps({
                title: 'Erro interno no servidor',
                description: res.message,
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 401') {
              setIsModalOpen(true)
              setWarningText(
                'Ops, algo deu errado. Tente novamente mais tarde.',
              )
              setClicado(false)
            } else if (res.message === 'Request failed with status code 408') {
              alert('Tempo de sessão expirado')
            } else if (res.message === 'Request failed with status code 404') {
              setIsModalOpen(true)
              setWarningText(
                'Não localizamos seu cpf na base de dados. Por favor entre em contato com a Previericsson.',
              )
              setClicado(false)
            } else if (res.message === 'Request failed with status code 406') {
              setIsModalOpen(true)
              setWarningText(
                'CPF já inscrito no plano. Para mais informações entre em contato com a Previericsson.',
              )
              setClicado(false)
            } else if (res.message === 'Request failed with status code 417') {
              setIsModalOpen(true)
              setWarningText(
                'Não foi possível enviar SMS. Verifique o telefone informado.',
              )
              setClicado(false)
            } else {
              setIsModalOpen(true)
              setWarningText(
                'Ops, algo deu errado. Tente novamente mais tarde.',
              )
              setClicado(false)
            }
          })
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      configData,
      history,
      setConfigData,
      setErroProps,
      setUserData,
      tipoContato,
      userData,
    ],
  )

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleConfirmar = useCallback(e => {
    e.preventDefault()
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Informe seus dados para prosseguir:</strong>

          <Form ref={formRef} onSubmit={handleSubmit} initialData={userData}>
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              // onBlur={(e) => handleAdmissao(e.target.value)}
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              id="email"
              type="email"
              placeholder="E-mail"
              maxLength={150}
            />

            <RadioButton>
              <label>Enviar código de confirmação por: </label>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'S'}
                  onClick={() => setTipoContato('S')}
                >
                  SMS
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'E'}
                  onClick={() => setTipoContato('E')}
                >
                  E-mail
                </BtnContato>
              </div>
            </RadioButton>
            <InputHidden name="tipContato" value={tipoContato} type="hidden" />
          </Form>

          <Line />
          <div>
            <small>
              Ao continuar navegando no site, você concorda com as condições de{' '}
              <a
                href="https://previericsson.com.br/wp-content/uploads/2020/11/POLI%CC%81TICA-DE-PROTEC%CC%A7A%CC%83O-DE-DADOS-PESSOAIS_06_11.pdf"
                target="_blank"
              >
                Privacidade
              </a>
              .
            </small>
            <Switch
              onChange={toggleAcceptTerms}
              checked={acceptTerms}
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={40}
              handleDiameter={16}
              offColor="#DEE3E1"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
        </Content>

        <Button
          type="button"
          color="greenBlue"
          width="large"
          disabled={!acceptTerms || clicado}
          onClick={handleConfirmar}
        >
          {clicado ? 'Aguarde...' : 'Confirmar'}
        </Button>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default Register
