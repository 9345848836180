import React, { useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Switch from 'react-switch'
import moment from 'moment'
import * as Yup from 'yup'

import { FiMail, FiSmartphone, FiUser } from 'react-icons/fi'
import { FaRegAddressCard } from 'react-icons/fa'
import { MdSecurity } from 'react-icons/md'
import { BiCake } from 'react-icons/bi'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'

import InputSelect from '../../components/InputSelect'
import InputHidden from '../../components/InputHidden'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'

import { UserData } from '../../utils/interfaces'
import { Container, Content, Line } from './styles'
import calculaIdade from '../../utils/calculaIdade'
import validaCPF from '../../utils/validaCPF'

const PersonalInfo: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )

  const [acceptInfo, setAcceptInfo] = usePersistedState('acceptInfo', true)
  const [patrocinadora, setPatrocinadora] = useState({
    label:
      userData.dcrPatrocinadora === undefined ? '' : userData.dcrPatrocinadora,
    value: userData.patrocinadora === undefined ? '' : userData.patrocinadora,
  })

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const toggleAcceptInfo = useCallback(() => {
    setAcceptInfo(!acceptInfo)
  }, [acceptInfo, setAcceptInfo])

  const handleChangePatrocinadora = useCallback(e => {
    const t = e
    setPatrocinadora(t)
  }, [])

  const handleSubmit = useCallback(
    async (data: UserData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          birthdate: Yup.string()
            .required('Data de nascimento obrigatória')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.birthdate.split('/').reverse().join('-')) ||
                data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  data.birthdate.split('/').reverse().join('-'),
                ).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(data.birthdate.split('/').reverse().join('-')) <=
                  115 || data.birthdate === '',
            ),
          cpf: Yup.string()
            .required('Digite o CPF do titular')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular obrigatório'),
          patrocinadora: Yup.string().required('Patrocinadora é obrigatória'),
        })

        await schema.validate(data, { abortEarly: false })

        const dataNascForm = data.birthdate.split('/').reverse().join('-')

        await setUserData({
          ...userData,
          name: data.name,
          birthdate: dataNascForm,
          cpf: data.cpf,
          patrocinadora: patrocinadora.value,
          dcrPatrocinadora: patrocinadora.label,
          matricula: data.matricula,
        })

        history.push('/contribution')
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [history, patrocinadora, setUserData, userData],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Complete o seu cadastro:</strong>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: userData.name,
              birthdate:
                userData.birthdate === undefined
                  ? ''
                  : userData.birthdate.split('-').reverse().join('/'),
              cpf: userData.cpf,
              phone: userData.phone,
              email: userData.email,
              matricula: userData.matricula,
            }}
          >
            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            <Input
              icon={BiCake}
              name="birthdate"
              placeholder="Data de nascimento"
              mask="date"
            />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              id="email"
              type="email"
              placeholder="E-mail"
              maxLength={150}
            />
            <InputSelect
              name="patrocinadora"
              value={patrocinadora}
              options={[
                { label: 'Ericsson Telecomunicações S.A.', value: 21 },
                { label: 'EISA (Ericsson Inovação S.A)', value: 115 },
                {
                  label: 'Venturus Centro de Inovação Tecnológica',
                  value: 150,
                },
                {
                  label:
                    'Cooperativa de Economia e Crédito Mútuo dos Funcionários da Ericsson',
                  value: 15,
                },
                {
                  label: 'Previ-Ericsson – Sociedade de Previdência Privada',
                  value: 1,
                },
              ]}
              onChange={e => handleChangePatrocinadora(e)}
              placeholder="Empresa"
            />
            <Input
              name="matricula"
              placeholder="Matrícula"
              type="tel"
              icon={FaRegAddressCard}
              maxLength={10}
            />
          </Form>

          <Line />

          <div>
            <small>Aceito receber informações por e-mail e whatsapp</small>
            <Switch
              onChange={toggleAcceptInfo}
              checked={acceptInfo}
              checkedIcon={false}
              uncheckedIcon={false}
              height={18}
              width={36}
              handleDiameter={16}
              offColor="#EB6A9F"
              offHandleColor="#e7dede"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
        </Content>

        <Button type="submit" color="greenBlue" onClick={handleClick}>
          Próximo
        </Button>
      </Container>
    </>
  )
}

export default PersonalInfo
