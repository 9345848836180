import React from 'react'

import endImage from '../../assets/end-image.svg'

import Header from '../../components/Header'
import { Container, Content } from './styles'

const End: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! Seu pedido de adesão ao Plano de Contribuição Definida
            PreviEricsson foi salvo com sucesso!
          </strong>

          <img src={endImage} alt="endImage" />

          <p>
            Agora, nossos especialistas irão conferir todos os detalhes para a
            conclusão da sua adesão.
          </p>

          <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a href="https://previericsson.com.br/">www.previericsson.com.br</a>
          </div>
        </Content>
      </Container>
    </>
  )
}

export default End
